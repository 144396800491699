import { createAsyncThunk } from '@reduxjs/toolkit';

const retryAsync = async <T>(asyncFunc: (...args: any[]) => Promise<T>, args: any[], retries: number): Promise<T> => {
  try {
    return await asyncFunc(...args);
  } catch (error) {
    console.log(`Retry no ${retries} with error: ${JSON.stringify(error)}`)
    if (retries === 1) throw error;
    return await retryAsync(asyncFunc, args, retries - 1);
  }
};

export const createAsyncThunkWithRetry = <Returned, ThunkArg = void>(
  typePrefix: string,
  payloadCreator: (arg: ThunkArg) => Promise<Returned>,
) => {
  return createAsyncThunk(typePrefix, async (arg: ThunkArg) => {
    return await retryAsync(payloadCreator, [arg], 3);
  });
};

export const createAsyncThunkWithRetryAndApi = <Returned, ThunkArg = void>(
  typePrefix: string,
  payloadCreator: (arg: ThunkArg, thunkAPI?: any) => Promise<Returned>,
  retries: number = 3,
) => {
  return createAsyncThunk(typePrefix, async (arg: ThunkArg, thunkAPI) => {
    return await retryAsync(payloadCreator, [arg, thunkAPI], retries);
  });
};