import {
  Box,
  Button,
  CircularProgress,
  GlobalStyles,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../utils/hooks';
import { getIsLoading, getParkingTickets } from '../../modules/parkingTickets/selectors';
import React, { useEffect, useState } from 'react';
import { fetchParkingTickets } from '../../modules/parkingTickets/operations';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { formatInTimeZone } from 'date-fns-tz';
import { getTicketDetails } from '../../modules/tickets/operations';
import { ReturnButton, VioletButton } from '../../components/styled-components';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Unstable_Grid2';
import { getEmbeddedLprScanner, getEnabledFeatures } from '../../modules/layout/selectors';
import { InputField } from '../../components/PlateForm';
import { AppFeature } from '../../utils/device';
import ValidationStatus from './ValidationStatus';

const getDateTmp = (dateStr: string) => {
  return formatInTimeZone(dateStr, 'Europe/Warsaw', 'dd.MM.yyyy');
};

const getTimeTmp = (dateStr: string) => {
  return formatInTimeZone(dateStr, 'Europe/Warsaw', 'HH:mm');
};

const displayShortDate = (fromStr: string, toStr: string): boolean => {
  return getDateTmp(fromStr) === getDateTmp(toStr) && getTimeTmp(fromStr) === '00:00' && getTimeTmp(toStr) === '23:59';
};

const SearchResult = () => {
  const { t } = useTranslation(['common', 'pages']);
  const [searchParams] = useSearchParams();
  const [plateInput, setPlateInput] = useState<string>('');
  const [showBuyTicketButton, setShowBuyTicketButton] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const parkingTickets = useAppSelector((s) => getParkingTickets(s.parkingTickets));
  const embeddedLprScanner = useAppSelector((x) => getEmbeddedLprScanner(x.layout));
  const enabledFeatures = useAppSelector((s) => getEnabledFeatures(s.layout));
  const isLoading = useAppSelector((s) => getIsLoading(s.parkingTickets));

  const handleRowClick = async (ticketId: string) => {
    const ticket = await dispatch(getTicketDetails(ticketId)).unwrap();
    if (ticket) {
      if (ticket.ticketWarnings.length > 0) {
        navigate(`/ticket/invalid/${ticketId}?r=/plate_search/result?search=${plateInput}&plateSearch=true`);
        return;
      } else {
        navigate(`/ticket/valid/${ticketId}?r=/plate_search/result?search=${plateInput}`);
        return;
      }
    }
  };

  const handleOpenCameraPlateReader = () => {
    if (window.BtDevice) {
      window.BtDevice.startLprScan();
      window.BtApp.handleLprScan = (plate: string) => {
        console.log('Zeskanowano: ' + plate);
        if (plate !== undefined) {
          setPlateInput(plate);
        }
      };
    }
  };
  const handleSearchTextChange = (e) => {
    setPlateInput(e.target.value);
  };

  useEffect(() => {
    if (plateInput) {
      dispatch(fetchParkingTickets(plateInput));
    }
  }, [plateInput]);

  useEffect(() => {
    const plate = searchParams.get('search');
    if (plate) {
      setPlateInput(plate);
    }
  }, []);

  useEffect(() => {
    if (enabledFeatures?.find((x) => x === AppFeature.TicketSale)) {
      setShowBuyTicketButton(true);
    }
  }, enabledFeatures);

  return (
    <>
      <GlobalStyles
        styles={{
          body: { backgroundColor: '#FFB039' },
          html: { backgroundColor: '#FFB039' },
        }}
      />
      <Stack direction="column" spacing={6} sx={{ py: 6, backgroundColor: '#FFB039' }}>
        <Stack direction={'row'} justifyContent={'space-between'}>
          <ReturnButton onClick={() => navigate('/plate_search')}>
            <>{t('common:buttons.back')}</>
          </ReturnButton>
          {showBuyTicketButton ? (
            <VioletButton onClick={() => navigate('/tickets')}>
              <>{t('common:buttons.tickets')}</>
            </VioletButton>
          ) : null}
        </Stack>

        <Typography sx={{ fontSize: '1.875rem', lineHeight: '3.2rem', color: 'white', px: 2, textAlign: 'center' }}>
          {t('common:plates.enterPlateNumber')}
        </Typography>
        <Grid container rowSpacing={2}>
          <Grid xs={12} md={embeddedLprScanner ? 11 : 12}>
            <InputField
              autoFocus={!embeddedLprScanner}
              onChange={(e) => handleSearchTextChange(e)}
              value={plateInput}
            ></InputField>
          </Grid>
          {embeddedLprScanner && (
            <Grid xs={1} alignSelf="center">
              <Button onClick={handleOpenCameraPlateReader}>
                <Box component="img" src="/images/camera_scanner.png" justifyContent="center"></Box>
              </Button>
            </Grid>
          )}
        </Grid>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align={'center'}>{t('pages:searchResult.tableHeaders.ticketType')}</TableCell>
                <TableCell align={'center'}>{t('pages:searchResult.tableHeaders.plateNo')}</TableCell>
                <TableCell align={'center'}>{t('pages:searchResult.tableHeaders.validity')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading ? (
                <TableRow>
                  <TableCell colSpan={3} align={'center'} sx={{ justifyContent: 'center', textAlign: 'center' }}>
                    <CircularProgress color="inherit" />
                  </TableCell>
                </TableRow>
              ) : null}
              {!isLoading && parkingTickets.length == 0 ? (
                <TableRow>
                  <TableCell colSpan={3} align={'center'} sx={{ justifyContent: 'center', textAlign: 'center' }}>
                    <Typography>{t('pages:searchResult.noResults')}</Typography>
                  </TableCell>
                </TableRow>
              ) : null}
              {parkingTickets?.map((p) => (
                <TableRow key={p.uuid} onClick={() => handleRowClick(p.uuid)}>
                  <TableCell align={'center'}>{p.variantName}</TableCell>
                  <TableCell align={'center'}>{p.question.answer}</TableCell>
                  <TableCell align={'left'}>
                    <Stack direction={'row'} alignItems={'center'} justifyContent={'flex-start'} spacing={1}>
                      <ValidationStatus isValid={p.valid} />
                      {displayShortDate(p.validSince, p.validUntil) ? (
                        <b>{getDateTmp(p.validSince)}</b>
                      ) : (
                        <>
                          {getDateTmp(p.validSince)} <b>{getTimeTmp(p.validSince)}</b> - {getDateTmp(p.validUntil)}{' '}
                          <b>{getTimeTmp(p.validUntil)}</b>
                        </>
                      )}
                    </Stack>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
    </>
  );
};

export default SearchResult;
